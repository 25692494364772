import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { Department } from '@vision/ui/interfaces';
import { axiosBaseQuery } from './base.service';
/*
const mockDepartments: Department[] = [
  {
    id: '358079ac-a5de-4dda-b044-38ac8bf30e4c',
    type: 'Department',
    name: 'deneme_hesap',
    code: 'deneme_hesap',
    status: 'active',
    flow_id: null,
    parent_id: '41e7a3a5-cc0e-441b-ab42-2d1785a8719b',
    logo_url: null,
    favicon_url: null,
    created_at: '2024-01-24T16:57:38.821+03:00',
  },
  {
    id: 'b95b0d5e-2bb3-4fbf-b65a-78e9c6130b4b',
    type: 'Department',
    name: 'deneme_hesap2',
    code: 'deneme_hesap2',
    status: 'active',
    flow_id: null,
    parent_id: '41e7a3a5-cc0e-441b-ab42-2d1785a8719b',
    logo_url: null,
    favicon_url: null,
    created_at: '2024-01-24T16:57:39.008+03:00',
  },
  {
    id: 'd8ccca3c-7024-49ee-9097-e7e143907375',
    type: 'Department',
    name: 'deneme_test',
    code: 'deneme_test',
    status: 'active',
    flow_id: null,
    parent_id: '358079ac-a5de-4dda-b044-38ac8bf30e4c',
    logo_url: null,
    favicon_url: null,
    created_at: '2024-01-24T16:57:39.232+03:00',
  },
  {
    id: 'e06196cc-a672-48d4-8a7b-63182be007c6',
    type: 'Department',
    name: 'department 1',
    code: 'department-1',
    status: 'active',
    flow_id: null,
    parent_id: '41e7a3a5-cc0e-441b-ab42-2d1785a8719b',
    logo_url: null,
    favicon_url: null,
    created_at: '2023-11-29T15:37:44.108+03:00',
  },
  {
    id: 'bfd3cfa4-1e6b-43a5-a7c6-cf472d3e4a40',
    type: 'Department',
    name: 'department 2',
    code: 'department-2',
    status: 'active',
    flow_id: null,
    parent_id: 'e06196cc-a672-48d4-8a7b-63182be007c6',
    logo_url: null,
    favicon_url: null,
    created_at: '2023-11-29T15:37:44.174+03:00',
  },
  {
    id: '9ea0b181-f1d4-4c50-8471-c1871681167b',
    type: 'Department',
    name: 'department 3',
    code: 'department-3',
    status: 'active',
    flow_id: null,
    parent_id: 'e06196cc-a672-48d4-8a7b-63182be007c6',
    logo_url: null,
    favicon_url: null,
    created_at: '2023-11-29T15:37:44.360+03:00',
  },
  {
    id: '93d1cc12-d824-4e2e-aafc-5539f241ab60',
    type: 'Department',
    name: 'department 4',
    code: 'department-4',
    status: 'active',
    flow_id: null,
    parent_id: 'bfd3cfa4-1e6b-43a5-a7c6-cf472d3e4a40',
    logo_url: null,
    favicon_url: null,
    created_at: '2023-11-29T15:37:44.674+03:00',
  },
];
*/
export const DepartmentsService = createApi({
  reducerPath: 'DepartmentsService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getDepartments: builder.query<Department[], { accountId: string }>({
      query: ({ accountId }) => ({
        url: '/v1/departments',
        method: HttpMethod.GET,
        params: {
          account_id: accountId,
        },
      }),
      /*
      transformResponse: () => {
        return mockDepartments;
      },*/
    }),
  }),
});

export const { useGetDepartmentsQuery } = DepartmentsService;
