import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import {
  ApiResponse,
  ApiResponseSimple,
  ReportDetail,
  ReportDetailFilterOptions,
  ReportFeedbackRequest,
  ReportFeedbackResponseCollection,
  ReportFeedbackStaticResponse,
  ReportWidget,
  UpdateReportRequest,
} from '@vision/ui/interfaces';
import { ReportChartJobIdResponse } from '../interfaces/report-chart-job-id-response';
import { ReportChartMeasureApiPathType } from '../pages/ReportsDetailGraphic/constants';
import { createHttpExtraOptions, insertIfObject } from '../utils';
import { axiosBaseQuery } from './base.service';
import { ReportsService } from './reports.service';

// ReportsDetailService diye değiştirilebilir.
export const ReportWidgetsService = createApi({
  reducerPath: 'ReportWidgetsService',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['GetReportWidgets'],
  endpoints: (builder) => ({
    getReportWidgets: builder.query<ApiResponse<ReportWidget[]>, { nodeId?: string; reportId: string }>({
      query: ({ nodeId, reportId }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/dashboard_widgets/list`,
        method: HttpMethod.GET,
      }),
      providesTags: ['GetReportWidgets'],
    }),
    deleteReportWidget: builder.mutation<
      ApiResponse<ReportDetail>,
      { nodeId: string; reportId: string; widgetId: string }
    >({
      query: ({ nodeId, reportId, widgetId }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/dashboard_widgets/${widgetId}/delete`,
        method: HttpMethod.POST,
      }),
      // invalidatesTags: ['GetReportWidgets'],
      async onQueryStarted(result, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(ReportsService.util.invalidateTags(['GetFavoriteReports']));
      },
    }),
    getReport: builder.query<ApiResponse<ReportDetail>, { nodeId: string; reportId: string }>({
      query: ({ nodeId, reportId }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}`,
        method: HttpMethod.GET,
      }),
    }),
    updateReportDetail: builder.mutation<
      ApiResponse<ReportDetail>,
      { nodeId: string; reportId: string; data: UpdateReportRequest }
    >({
      query: ({ nodeId, reportId, data }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/update`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
    getFeedbackOrJobId: builder.query<
      ReportChartJobIdResponse | ApiResponseSimple<ReportFeedbackResponseCollection>,
      { params: ReportFeedbackRequest; url: ReportChartMeasureApiPathType; widgetId?: string; async?: boolean }
    >({
      query: ({ params, url, widgetId, async = true }) => ({
        url: `/v2/${url}`,
        method: HttpMethod.GET,
        params: {
          ...params,
          ...insertIfObject(!!async, { async }),
          dashboard_widget_id: widgetId,
        },
      }),
    }),
    getFeedbackStaticResult: builder.query<ReportFeedbackStaticResponse, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `/v2/feedback/statistic_result/${jobId}`,
        method: HttpMethod.GET,
      }),
    }),
    updateReportWidget: builder.mutation<
      ApiResponse<ReportDetail>,
      { nodeId: string; reportId: string; data: ReportWidget; widgetId: string }
    >({
      query: ({ nodeId, reportId, data, widgetId }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/dashboard_widgets/${widgetId}/update`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
      async onQueryStarted(result, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(ReportsService.util.invalidateTags(['GetReportGraphicDetail', 'GetFavoriteReports']));
      },
    }),

    updateReportDetailFilters: builder.mutation<
      ApiResponse<ReportDetail>,
      { nodeId: string; reportId: string; detail: ReportDetailFilterOptions }
    >({
      query: ({ nodeId, reportId, detail }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/update`,
        method: HttpMethod.POST,
        data: {
          detail,
        },
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
  }),
});

export const {
  useLazyGetReportWidgetsQuery,
  useDeleteReportWidgetMutation,
  useGetReportQuery,
  useLazyGetFeedbackOrJobIdQuery,
  useGetFeedbackStaticResultQuery,
  useUpdateReportWidgetMutation,
  useUpdateReportDetailMutation,
  useUpdateReportDetailFiltersMutation,
} = ReportWidgetsService;
