import { TreeSelectItemType } from '@vision/ui/interfaces';
import type * as charts from 'echarts/charts';
import type * as components from 'echarts/components';
import React from 'react';
import { ECExtensions, KnownRegisteredSeriesOption, TreemapSeriesNodeItemOption, Values } from './ECharts.types';

export const optionCreator =
  <T>(key: string, defaultProps?: T) =>
  ({ children, ...props }: React.PropsWithChildren<T>) => ({ [key]: { ...defaultProps, ...props } });

export const seriesOptionCreator =
  <T extends Values<KnownRegisteredSeriesOption>>(type: keyof KnownRegisteredSeriesOption, defaultProps?: T) =>
  ({ children, ...props }: React.PropsWithChildren<T>) => ({
    series: { ...defaultProps, ...props, type },
  });

export const chartLoader = (names: (keyof typeof charts)[]) => async () => {
  const module = await import('echarts/charts');

  return names.map((name) => module[name]);
};

export const componentLoader = (names: (keyof typeof components)[]) => async (): Promise<ECExtensions> => {
  const module = await import('echarts/components');

  return names.map((name) => module[name]);
};

export function convertTreeSelectItemTypeToTreeSeriesData(data: TreeSelectItemType[]): TreemapSeriesNodeItemOption[] {
  return data.map((item) => {
    return {
      name: item.label,
      value: item.value,
      children: convertTreeSelectItemTypeToTreeSeriesData(item.children),
      data: item.data,
    };
  });
}
