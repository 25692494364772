import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { ApiResponse, CatiCall } from '@vision/ui/interfaces';
import { buildEndpointWithQuery, createHttpExtraOptions } from '@vision/ui/utils';
import { axiosBaseQuery } from './base.service';

export const CatiListService = createApi({
  reducerPath: 'CatiListService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    catiList: builder.query<ApiResponse<CatiCall[]>, { query: string; accountId: string }>({
      query: ({ accountId, query }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${accountId}/cati_calls`, query),
        method: HttpMethod.GET,
      }),
    }),
    updateCatiListStatus: builder.mutation<void, { id: string; data: Partial<CatiCall> }>({
      query: ({ id, data }) => ({
        url: `/v2/cati_calls/${id}`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.catiList.updateCatiListtatus.{{status}}',
          },
        }),
      }),
    }),
    deleteMultipleCatiCalls: builder.mutation<void, { catiCallIds: string[] }>({
      query: ({ catiCallIds }) => ({
        url: `/v2/cati_calls/delete_multiple`,
        method: HttpMethod.POST,
        data: {
          cati_call_ids: catiCallIds,
        },
      }),
    }),
    deleteAllExceptIdsCatiCalls: builder.mutation<void, { query: string; exceptIds: string[], accountId: string }>({
      query: ({ query, exceptIds, accountId }) => ({
        url: `/v2/accounts/${accountId}/cati_calls/delete_all_except_ids`,
        method: HttpMethod.POST,
        data: {
          query: query,
          except_ids: exceptIds,
        },
      }),
    }),
  }),
});

export const {
  useLazyCatiListQuery,
  useUpdateCatiListStatusMutation,
  useDeleteMultipleCatiCallsMutation,
  useDeleteAllExceptIdsCatiCallsMutation,
} = CatiListService;
