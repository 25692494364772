import { DatesRangeValue } from '@mantine/dates';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReportDetail, ReportDetailFilterOptions, ReportWidget } from '@vision/ui/interfaces';
import { GRAPHIC_DEFAULT_DATE_RANGE } from '@vision/ui/pages/ReportsDetailGraphic/constants';
import { ReportWidgetsService } from '@vision/ui/services';
import { setReportChartDateRange } from '@vision/ui/utils';

export const DEFAULT_REPORT_FILTER_OPTIONS: ReportDetailFilterOptions = {
  from: undefined,
  to: undefined,
  channel_types: [],
  flow_id: [],
  nodes: [],
  tag_groups: [],
  tags: [],
};

function checkReportDetailFilters(detail?: ReportDetailFilterOptions): ReportDetailFilterOptions {
  if (!detail) {
    return DEFAULT_REPORT_FILTER_OPTIONS;
  }

  const filteredDetail: ReportDetailFilterOptions = {
    from: detail?.from ?? undefined,
    to: detail?.to ?? undefined,
    channel_types: Array.isArray(detail?.channel_types) ? detail.channel_types : [],
    flow_id: Array.isArray(detail?.flow_id) ? detail.flow_id : [],
    nodes: Array.isArray(detail?.nodes) ? detail.nodes : [], // aslında channel.
    tag_groups: Array.isArray(detail?.tag_groups) ? detail.tag_groups : [],
    tags: Array.isArray(detail?.tags) ? detail.tags : [],
  };

  return filteredDetail;
}

export interface ReportWidgetsStateType {
  reportDetail?: ReportDetail;
  widgets?: ReportWidget[];
  dateRange: DatesRangeValue;
  changedToDateRagne: number;
  selectedMainFilters: ReportDetailMainFilterType;
}

export interface ReportDetailMainFilterType {
  selectedChannelIds: Array<string>;
  selectedFlowIds: Array<string>;
  selectedUnitIds?: Array<string>;
}

const initialState: ReportWidgetsStateType = {
  reportDetail: null,
  widgets: [],
  dateRange: GRAPHIC_DEFAULT_DATE_RANGE,
  changedToDateRagne: 0,
  selectedMainFilters: {
    selectedChannelIds: [],
    selectedFlowIds: [],
    selectedUnitIds: [],
  },
};

export const ReportWidgetsState = createSlice({
  name: 'ReportWidgetsState',
  initialState,
  reducers: {
    updateDateRangeAction: (state, action) => {
      state.dateRange = action.payload;
      if (state.reportDetail && state.reportDetail.detail) {
        state.reportDetail.detail.from = action.payload[0]?.getTime();
        state.reportDetail.detail.to = action.payload[1]?.getTime();
      }
      state.changedToDateRagne = state.changedToDateRagne + 1;
    },

    updateReportMainFiltersAction: (state, { payload }: PayloadAction<ReportDetailMainFilterType>) => {
      state.selectedMainFilters = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(ReportWidgetsService.endpoints.getReport.matchFulfilled, (state, { payload }) => {
      const { detail } = payload.data;

      // Update state in one assignment
      Object.assign(state, {
        reportDetail: {
          ...payload.data,
          detail: checkReportDetailFilters(detail),
        },
        dateRange:
          detail?.from && detail?.to ? setReportChartDateRange(detail.from, detail.to) : GRAPHIC_DEFAULT_DATE_RANGE,
        selectedMainFilters: {
          selectedFlowIds: detail?.flow_id || [],
          selectedChannelIds: detail?.nodes || [],
          selectedUnitIds: detail?.unit_ids || [],
        },
      });
      /*state.reportDetail = {
        ...payload.data,
        detail: checkReportDetailFilters(detail),
      };

      // Set date range based on detail
      state.dateRange =
        detail?.from && detail?.to ? setReportChartDateRange(detail.from, detail.to) : GRAPHIC_DEFAULT_DATE_RANGE;

      // Update selected main filters
      state.selectedMainFilters.selectedFlowIds = detail?.flow_id || [];
      state.selectedMainFilters.selectedChannelIds = detail?.nodes || [];
      state.selectedMainFilters.selectedUnitIds = detail?.unit_ids || [];*/
    });
    builder.addMatcher(ReportWidgetsService.endpoints.getReportWidgets.matchFulfilled, (state, { payload }) => {
      state.widgets = payload.data;
    });
    builder.addMatcher(ReportWidgetsService.endpoints.deleteReportWidget.matchFulfilled, (state, { payload }) => {
      state.widgets = state.widgets.filter((widget) => widget.id !== payload.data.id);
    });
  },
});

export const { updateDateRangeAction, updateReportMainFiltersAction } = ReportWidgetsState.actions;
