import { ReportChartMeasureType } from '@vision/ui/interfaces';

// interface altına taşınacak
export type ReportChartCXMetricMeasureApiType =
  | 'feedback_count'
  | 'score'
  | 'positivity'
  | 'sentiment_score'
  | 'custom_score'
  | 'chat_rating'
  | 'time_spent_in_seconds'
  | 'action_time_read'
  | 'action_time_response'
  | 'action_time_internal_note'
  | 'action_time_archived'
  | 'customer_count';

export const ReportChartCXMetricMeasureApiTypeMapping: Partial<
  Record<ReportChartMeasureType, ReportChartCXMetricMeasureApiType>
> = {
  'feedback-count': 'feedback_count',
  'feedback-nps': 'score',
  'feedback-csat': 'positivity',
  'feedback-sentiment-score': 'sentiment_score',
  'feedback-weighted-score': 'custom_score',
  'feedback-chat-score': 'chat_rating',

  'action-time-completion': 'time_spent_in_seconds',
  'action-time-read': 'action_time_read',
  'action-time-response': 'action_time_response',
  'action-time-internal-note': 'action_time_internal_note',
  'action-time-archived': 'action_time_archived',

  'customer-count': 'customer_count',
};

// ReportChartCXMetricMeasureApiType değerini parametre alıp dönüş değeri ReportChartMeasureType olan fonksiyon
export function getReportChartMeasureTypeFromCXMetricMeasureApiType(
  cxMetricMeasureApiType: ReportChartCXMetricMeasureApiType,
): ReportChartMeasureType {
  return Object.entries(ReportChartCXMetricMeasureApiTypeMapping).find(
    ([_, value]) => value === cxMetricMeasureApiType,
  )?.[0] as ReportChartMeasureType;
}

// ReportChartCXMetricMeasureApiType array değerini parametre alıp dönüş değeri ReportChartMeasureType array olan fonksiyon yaz
export function getReportChartMeasureTypesFromCXMetricMeasureApiTypes(
  cxMetricMeasureApiTypes: ReportChartCXMetricMeasureApiType[],
): ReportChartMeasureType[] {
  return cxMetricMeasureApiTypes.map(getReportChartMeasureTypeFromCXMetricMeasureApiType);
}
