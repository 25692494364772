import { Box, Checkbox, CheckboxProps, createPolymorphicComponent } from '@mantine/core';
import clsx from 'clsx';
import { forwardRef } from 'react';
import classes from './CheckboxButton.module.scss';

interface CheckboxButtonProps extends CheckboxProps {}

const _CheckboxButton = forwardRef<HTMLInputElement, CheckboxButtonProps>(
  ({ className, classNames, ...props }, ref) => (
    <Box className={clsx(classes.checkboxButton, className)} data-checked={props.checked}>
      <Checkbox
        classNames={{
          inner: classes.inner,
          label: classes.label,
          root: classes.root,
        }}
        ref={ref}
        {...props}
      />
    </Box>
  ),
);

export const CheckboxButton = createPolymorphicComponent<'input', CheckboxButtonProps>(_CheckboxButton);
