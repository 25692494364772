import { ComboboxItemGroup } from '@mantine/core';
import i18n from '@vision/ui/i18n';
import {
  INode,
  ReportChartCompareTransform,
  ReportChartGroupCompare,
  ReportChartGroupCompareItemType,
  ReportChartGroupItemMeasure,
  ReportChartMeasureType,
  ReportChartThemeSerieItem,
  ReportFeedbackNameType,
  ReportFeedbackResponse,
  ReportFeedbackResponseDataArray,
} from '@vision/ui/interfaces';
import {
  convertArrayToGroupSelectItems,
  convertArrayToSelectItems,
  ensureArray,
  formatNumber,
  getValueByActiveLanguage,
} from '@vision/ui/utils';
import dayjs from 'dayjs';
import { Dictionary } from 'echarts/types/src/util/types';

export function getReportDataName(name: ReportFeedbackNameType): string {
  if (typeof name === 'string' || typeof name === 'number') {
    return name.toString();
  }

  return getValueByActiveLanguage(name) || Object.values(name)[0] || '';
}

export const createChannelSelectItems = (channelTypes: string[], nodes: INode[]): ComboboxItemGroup[] => {
  return channelTypes.map((channelType) => {
    const channels = nodes.filter((item) => item.type === channelType);
    return {
      group: i18n.t(`channelEnumTypes.${channelType.toLowerCase()}`),
      items: convertArrayToSelectItems(channels, (item) => ({
        label: item.name,
        value: item.id,
      })),
    } as ComboboxItemGroup;
  });
};

//  burada compare değişince hangi değerlerin silineceğini belirliyoruz
export function getReportChartCompareKey(keyType: ReportChartGroupCompareItemType[]): string[] {
  return ReportChartCompareTransform.filter((item) => keyType.includes(item.keyType)).flatMap(
    (item) => item.compareKey,
  );
}

function getLegendStatusByLabel(label: string, seriesItems: ReportChartThemeSerieItem[]): boolean {
  const item = seriesItems.find((color) => color.label === label);

  if (item?.hidden) {
    return false;
  }

  return true;
}

export function getLegendStatus(labels: string[], seriesItems: ReportChartThemeSerieItem[]): Dictionary<boolean> {
  if (!seriesItems.length) {
    return {};
  }

  return labels.reduce((acc, label) => {
    acc[label] = getLegendStatusByLabel(label, seriesItems);
    return acc;
  }, {} as Dictionary<boolean>);
}

export function isReportFeedbackResponseDataArray(data: ReportFeedbackResponse[]): boolean {
  return ensureArray(data).some((item) => Array.isArray(item.data));
}

export function isReportFeedbackResponseDataNumber(data: ReportFeedbackResponse[]): boolean {
  return ensureArray(data).some((item) => typeof item.data === 'number');
}

// isReportFeedbackResponseDataArray fonk buna göre düzelt
export function isReportFeedbackResponseDataArrayItem(
  data: ReportFeedbackResponse['data'],
): data is ReportFeedbackResponseDataArray {
  return Array.isArray(data);
}

export function secondsToHHMMSS(value: number): string {
  const hours = Math.floor(value / 3600);
  //  (Math/floor (/ x 60))
  const minutes = Math.floor((value - hours * 3600) / 60);
  // (Math/floor (- value (* (:data hours) 3600) (* (:data minutes) 60)))
  const seconds = Math.floor(value - hours * 3600 - minutes * 60);

  const hhMmSs = [
    { data: hours, trans: i18n.t('hours') },
    { data: minutes, trans: i18n.t('minutes') },
    { data: seconds, trans: i18n.t('seconds') },
  ].filter((val) => val.data > 0);

  return hhMmSs.map((val) => `${val.data} ${val.trans}`).join(' ');
}

export function reportChartLegendLabelFormatter(compare: ReportChartGroupCompareItemType, label: string): string {
  try {
    switch (compare) {
      // If the comparison is by day, we return the day of the week.
      case 'time-period-by-day':
        return dayjs.localeData().weekdays()[Number(label)];
      case 'time-period-by-month':
        // data is like January 1, February 2, March 3
        return dayjs()
          .month(Number(label) - 1)
          .format('MMMM');
      case 'user-archiving':
      case 'user-assigned':
      case 'user-responsible':
      case 'user-best-assigned':
      case 'user-worst-assigned':
        return label === ' ' ? i18n.t('unknownUser') : label;
      default:
        return label as string;
    }
  } catch (e) {
    console.error('reportChartLegendLabelFormatter error', e, 'compare', compare, 'label', label);
    return label;
  }
}

export function reportChartDefaultDataLabelFormatter(measure: ReportChartMeasureType, label: string): string {
  switch (measure) {
    case 'feedback-csat':
    case 'feedback-nps':
    case 'nps-kpi':
    case 'csat-kpi':
    case 'feedback-weighted-score':
      return formatNumber(Number(label), { style: 'decimal', maximumFractionDigits: 2 });
    case 'action-time-archived':
    case 'action-time-completion':
    case 'action-time-internal-note':
    case 'action-time-read':
    case 'action-time-response':
      return secondsToHHMMSS(Number(label));
    default:
      return label;
  }
}

export function reportChartSortLabels(labels: string[], compare: ReportChartGroupCompareItemType): string[] {
  switch (compare) {
    case 'time-period-by-day':
    case 'time-period-by-month':
    case 'time-period-by-hour':
      return labels.sort((a, b) => Number(a) - Number(b));
    default:
      return labels.sort();
  }
}

export function defaultReportChartNumberFormatter(value: number): string {
  return formatNumber(value, { style: 'decimal', maximumFractionDigits: 2 });
}

export function convertToSelectGroupItems(
  items: Array<ReportChartGroupItemMeasure | ReportChartGroupCompare>,
  groupTranslateKey: string,
  groupItemTranslateKey: string,
) {
  return convertArrayToGroupSelectItems(items, (item) => ({
    group: i18n.t(`page-reports:${groupTranslateKey}.${item.type}`),
    items: item.items.map((childItem) => ({
      label: i18n.t(`page-reports:${groupItemTranslateKey}.${childItem}`),
      value: childItem,
    })),
  }));
}
