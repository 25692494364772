import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { t } from 'i18next';
import { array, object, string } from 'yup';
import { TableChart1ChartValueComponent, TableChart1CompareRenderer } from '../../components';
import {
  DefaultReportChartCompareSchema,
  DefaultReportChartThemeFont,
  GRAPHIC_TITLE_MAX_CHAR_COUNT,
} from '../default-chart.config';

export const TableChart1DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: object().shape({
    selectedDateRange: array().of(string()).min(2, 'validation:rules.generic.required'),
    selectedMultipleMeasure: array().of(string()).min(1, 'validation:rules.generic.required'),
    selectedCompare: array()
      .of(string().required('validation:rules.generic.required'))
      .min(1, 'validation:rules.generic.required')
      .max(1, 'validation:rules.generic.max')
      .test('unique', 'validation:rules.generic.unique', (value) => {
        return new Set(value).size === value.length;
      }),
    selectedTheme: object().shape({
      title: object().shape({
        label: string()
          .max(
            GRAPHIC_TITLE_MAX_CHAR_COUNT,
            t('validation:rules.generic.max', {
              count: GRAPHIC_TITLE_MAX_CHAR_COUNT,
            }),
          )
          .required('validation:rules.reports.selectedTheme.title.label.required'),
      }),
    }),
    selectedCompareCriteria: object().shape({
      ...DefaultReportChartCompareSchema,
    }),
  }),
  measures: null,
  chartValueComponent: TableChart1ChartValueComponent,
  hideCompare: true,
  compareRendererComponent: TableChart1CompareRenderer,
  customize: {
    active: (values) => !!values.selectedMultipleMeasure.length && !!values.selectedCompare.length,
  },
  defaultTheme: {
    active: (values) => !!values.selectedMultipleMeasure.length && !!values.selectedCompare.length,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
  },
  screen: {
    width: 800,
    height: 600,
  },
};
